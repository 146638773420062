import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import moment from 'moment';
import stringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';

var hash = require('object-hash');

function InviteUser(props) {
    const [emailAddress, setEmailAddress] = useState("");
    const [userRole, setUserRole] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [userManagUrl, setUserManagUrl] = useState("");
    const [roles, setRoles] = useState(null);
    const [clients, setClients] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [sites, setSites] = useState(null);
    const [siteIds, setSiteIds] = useState([]);

    const history = useHistory();

    const setUserRoles = function (roles) {
        var tempRoles = [];
        var i = 0;
        while (true) {
            if (roles[i] === undefined) {
                break;
            } else {
                tempRoles.push(roles[i])
                i++;
            }
        }
        setRoles(tempRoles);
    }

    const submit = function () {
        if (canSave) {
            var invite = new Object();
            invite.emailAddress = emailAddress;
            invite.role = userRole;
            invite.status = 0;
            invite.organisationId = props.organisationId;
            invite.organisationName = props.organisationName;
            var nowDate = moment.now();
            var stringNowDate = moment(nowDate).format("DD/MM/yyyy HH:mm");
            invite.dateCreated = stringNowDate;

            //do api add invite when endpoint there
            Api.addInvitation(
                () => history.push(userManagUrl),
                (error) => console.log(error),
                emailAddress, userRole, clientId, siteIds, 
                props.organisationId,
                props.token
            )
        }
    }

    const toggleSiteId = function (siteId) {
        setSiteIds(arrayUtilities.toggle(siteId, siteIds));
    };

    useEffect(() => {
        if (emailAddress === null || emailAddress === "" || userRole === null) {
            setCanSave(false);
        } else {
            let emailRegex = /\S+@\S+\.\S+/;
            if (emailRegex.test(emailAddress)) {
                setCanSave(true);
            } else {
                setCanSave(false);
            }

        }
    }, [emailAddress, userRole])

    useEffect(() => {
        if (!stringHelpers.isNullOrEmpty(clientId)) {
            Api.getClientSites((data) => {
                setSites(data);
            }, () => {
                setSites(null);
                setSiteIds([]);                
            }, clientId, props.organisationId, props.token)
        } else {
            setSites(null);
            setSiteIds([]);
        }
    }, [clientId])

    useEffect(() => {
        setUserRoles(Enums.UserRoles);
        setUserManagUrl("/organisation/"+ props.organisationId+"/user-management");

        Api.getClients((data) => {
            setClients(data);
        }, () => {}, props.organisationId, props.token);
    }, [])

    return (
        <>
            {roles !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Invite User</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <div className="row">
                                        <div className=" col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Email Address*</p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" autoFocus={true} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">User Role*</p>
                                        </div>
                                        <div className="col-md-5 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-role" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{userRole !== null && Enums.UserRoles[userRole]}{userRole === null && <>Select the User Role</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-role" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {roles.map((userRole, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setUserRole(i - 1)}>{userRole}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(userRole === 4 || userRole === 5 || userRole === 6) && clients && clients !== null &&
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Client</p>
                                            </div>
                                            <div className="col-md-5 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-client" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {clientId !== null && <>{clients.find(x => x.id === clientId)?.name}</>}
                                                            {clientId === null && <>Select client</>}
                                                        </button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-client" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                {clients.map((client, index) => 
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setClientId(client.id)} key={client.id} index={index}>{client.name}</button>    
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(userRole === 4 || userRole === 5 || userRole === 6) && !stringHelpers.isNullOrEmpty(clientId) && !arrayUtilities.isNullOrEmpty(sites) &&
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Sites</p>
                                            </div>
                                            
                                            <div className="col-md-5 col-12 ">
                                            {sites.map((site, i) => {
                                                  return <div className="custom-control custom-checkbox" key={hash( { site, siteIds })} index={i}>
                                                      <input className="custom-control-input" type="checkbox" checked={siteIds.includes(site.id)} id={`site-${site.id}`} onChange={() => toggleSiteId(site.id) } />
                                                      <label className="custom-control-label col-form-label col-form-label-sm" htmlFor={`site-${site.id}`}>{site.name}</label>
                                                  </div>
                                              }
                                              )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={userManagUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default InviteUser;