import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import moment from 'moment';
import Loader from '../shared/loader';
import Pagination from '../shared/pagination';

function Camera(props) {
    const [cameraId, setCameraId] = useState(props.match.params.cameraId);
    const [siteId, setSiteId] = useState(props.match.params.siteId);
    const [siteUrl, setSiteUrl] = useState("");
    const [site, setSite] = useState(null);
    const [camera, setCamera] = useState(null);

    const [cameraName, setCameraName] = useState("");
    const [macAddress, setMacAddress] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [inTag, setInTag] = useState(null);
    const [outTag, setOutTag] = useState(null);
    const [isExclusionCamera, setIsExclusionCamera] = useState(false);
    const [isBayMonitoringCamera, setIsBayMonitoringCamera] = useState(false);
    const [status, setStatus] = useState(false);

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [heartbeats, setHeartbeats] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    const history = useHistory();

    const getCamera = function () {
        Api.getCamera(
            (data) => setCamera(data),
            (error) => console.log(error),
            props.organisationId,
            cameraId,
            props.token
        )
    };
    const submit = function () {
        if (canSave) {
            Api.addCamera(
                (data) => {
                    setCamera(data)
                    setIsNew(false);
                    setErrorMessage("");
                    history.push("camera/" + data.id);
                },
                (error) => setErrorMessage(error),
                cameraName, macAddress, ipAddress, inTag, outTag, isExclusionCamera, isBayMonitoringCamera, status,
                props.organisationId,
                siteId,
                props.token
            );
        } else {
            // validation messages
        }
    }

    const edit = function () {
        if (canSave) {
            Api.editCamera(
                (data) => {
                    setIsInEdit(false);
                    setCamera(data);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                cameraName, macAddress, ipAddress, inTag, outTag, isExclusionCamera, isBayMonitoringCamera, status,
                props.organisationId,
                cameraId,
                siteId,
                props.token
            );
        } else {
            // validation messages
        }
    }

    const getHeartbeats = function() {
        Api.getCameraHeartbeats(
            (data) => setHeartbeats(data),
            (error) => console.log(error),
            cameraId, pageNumber,
            props.organisationId,
            props.token
        )
    };
    
    useEffect(() => {
        if (camera !== null && camera !== undefined) {
            setCameraName(camera.name);
            setMacAddress(camera.macAddress);
            setIpAddress(camera.ipAddress);
            setInTag(camera.inTag);
            setOutTag(camera.outTag);
            setIsExclusionCamera(camera.isExclusionCamera);
            setIsBayMonitoringCamera(camera.isBayMonitoringCamera);
            setStatus(camera.isActive);
        }
        setErrorMessage("");
    }, [camera, isInEdit]);

    const changePageNumber = function(num){
        setPageNumber(num);
    }

    useEffect(() => {
        getHeartbeats();
    }, [pageNumber]);

    useEffect(() => {
        if ((cameraName === null || cameraName === "") ||
            (macAddress === null || macAddress === "")) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [cameraName, macAddress]);

    useEffect(() => {
        if (site !== null) {
                setSiteUrl("/organisation/"+props.organisationId+"/client/"+ site.clientId + "/site/" + siteId)
        }
    }, [site]);

    useEffect(() => {
        if (cameraId !== null && cameraId !== undefined) {
            getCamera();
            getHeartbeats();
        } else {
            setIsNew(true);
        }
        if(site === null && siteId !== null){
            Api.getSite(
                (data) => setSite(data),
                (error) => console.log(error),
                siteId,
                props.organisationId,
                props.token
            )
        }
    }, []);

    return (
        <>
            {!camera && !isNew && <Loader />}{camera && camera !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Camera - {camera.name}</h5>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={siteUrl}><i className="fas fa-reply"></i> Back</Link>
                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <i className="fas fa-times"></i> Delete Camera</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Details</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                {!isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Camera Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">MAC Address</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.macAddress}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">IP Address</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.ipAddress}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">In Tag</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.inTag}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Out Tag</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.outTag}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Exclusion Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.isExclusionCamera && <i className="fas fa-check text-success"></i>}{!camera.isExclusionCamera && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>                                        
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Bay Monitoring Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.isBayMonitoringCamera && <i className="fas fa-check text-success"></i>}{!camera.isBayMonitoringCamera && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{camera.isActive && <i className="fas fa-check text-success"></i>}{!camera.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                    </div>
                                </>}
                                {isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                    {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Camera Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={cameraName} onChange={(e) => setCameraName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">MAC Address<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={macAddress} onChange={(e) => setMacAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">IP Address</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">In Tag<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown ">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{inTag && <>{inTag}</>}{!inTag && <>Select Direction</>}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setInTag("towards")}>Towards</button>
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setInTag("away")}>Away</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Out Tag<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown ">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{outTag && <>{outTag}</>}{!outTag && <>Select Direction</>}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setOutTag("towards")}>Towards</button>
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setOutTag("away")}>Away</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Exclusion Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch1" checked={isExclusionCamera} onChange={() => setIsExclusionCamera(!isExclusionCamera)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                {isExclusionCamera && <>Yes</>}{!isExclusionCamera && <>No</>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Bay Monitoring Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch2" checked={isBayMonitoringCamera} onChange={() => setIsBayMonitoringCamera(!isBayMonitoringCamera)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch2"></label>
                                                {isBayMonitoringCamera && <>Yes</>}{!isBayMonitoringCamera && <>No</>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch2" checked={status} onChange={() => setStatus(!status)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch2"></label>
                                                {status && <>Active</>}{!status && <>Deactivated</>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Webhook URLs</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Camera Type</th>
                                                <th scope="col">Webhook URL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <img src="/assets/img/logos/cameras/mav.svg" alt="Mav" style={ { maxHeight: "25px"}} />
                                                </th>
                                                <td>
                                                    https://api.advancedparking.tech/{props.organisationId}/webhooks/mav&nbsp;
                                                    <button className='btn' onClick={() => navigator.clipboard.writeText(`https://api.advancedparking.tech/${props.organisationId}/webhooks/mav`)}><div className="icon-container"><i className="fas fa-copy"></i></div></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <img src="/assets/img/logos/cameras/mobotix.svg" alt="Mobotix" style={ { maxHeight: "25px"}} />
                                                </th>
                                                <td>
                                                    https://api.advancedparking.tech/{props.organisationId}/webhooks/mobotix&nbsp;
                                                    <button className='btn' onClick={() => navigator.clipboard.writeText(`https://api.advancedparking.tech/${props.organisationId}/webhooks/mobotix`)}><div className="icon-container"><i className="fas fa-copy"></i></div></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <img src="/assets/img/logos/cameras/hikvision.svg" alt="Mobotix" style={ { maxHeight: "25px"}} />
                                                </th>
                                                <td>
                                                    https://api.advancedparking.tech/{props.organisationId}/webhooks/hikvision&nbsp;
                                                    <button className='btn' onClick={() => navigator.clipboard.writeText(`https://api.advancedparking.tech/${props.organisationId}/webhooks/hikvision`)}><div className="icon-container"><i className="fas fa-copy"></i></div></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                </div>
                            </div>


                            {heartbeats !== null &&
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                            <button className="button-card-header button-no-style collapsed" id="camera-status-button" type="button" data-toggle="collapse" data-target="#cameras-status" aria-expanded="true" aria-controls="cameras-status">
                                                    Camera Status <i className="fas fa-chevron-down"></i></button></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse"  id="cameras-status" aria-labelledby="Tag List" data-parent="#camera-status-button">
                                <div className="card-body bg-light border-top details-container">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>No.</th>
                                                <th className="text-center">Date/Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {heartbeats !== null && heartbeats.items.map((item, i = 0) => {
                                            i++;
                                            var number = 100 * pageNumber + i;
                                            var targetId = "#target-" + item.id;
                                            return(
                                                <>
                                                    <tr>
                                                        <td><button className="button-no-style" data-toggle="modal" data-target={targetId}><h6 className="mb-0">{number}</h6></button></td>
                                                        <td className="text-center">{item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")} {moment(item.timestamp).add(1, 'hour').format("hh:mm")}</>}{item.timestamp === null && <>Unknown</>}</td>
                                                    </tr>
                                                    <div className="modal fade" id={targetId.substr(1)} tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="deleteModalLabel">Camera Heartbeat</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Date/Time</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        {item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")} {moment(item.timestamp).add(1, 'hour').format("hh:mm")}</>}{item.timestamp === null && <>Unknown</>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Time Mode</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.timeMode}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">
                                                                                current RTC Error
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.currentRtcError}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Last Sync Time</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        {item.lastSyncTime !== null && <>{moment(item.lastSyncTime).format("DD/MM/yyyy")} {moment(item.lastSyncTime).add(1, 'hour').format("hh:mm")}</>}{item.lastSyncTime === null && <>Unknown</>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Last Sync Correction</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        {item.lastSyncCorrection !== null && <>{moment(item.lastSyncCorrection).format("DD/MM/yyyy")} {moment(item.lastSyncCorrection).add(1, 'hour').format("hh:mm")}</>}{item.lastSyncCorrection === null && <>Unknown</>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Last Sync Source</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.lastSyncSource}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Last Sync Info</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.lastSyncInfo}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Startup Reason</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.startupReason}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Uptime</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        {item.uptime !== null && <>{moment(item.uptime).format("DD/MM/yyyy")} {moment(item.uptime).add(1, 'hour').format("hh:mm")}</>}{item.uptime === null && <>Unknown</>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                           <p className="font-weight-semi-bold  mt-1 mb-2">SD Read Only</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.sdReadOnly && <i className="fas fa-check text-success"></i>}{!item.sdReadOnly && <i className="fas fa-times text-danger"></i>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">SAF Count</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.safCount.toLocaleString()}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">SAF Unsent</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.safUnsent}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">SAF Failed</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.safFailed}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">SAF Expired</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.safExpired}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 ">
                                                                            <p className="font-weight-semi-bold  mt-1 mb-2">Interval</p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {item.interval}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row ">
                                        <div className="col-auto ">
                                            <Pagination totalResults={heartbeats.totalCount} currentPage={pageNumber} changePageNumber={changePageNumber}/>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this camera?</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteCamera(() => history.push(siteUrl), props.organisationId, cameraId, props.token)}>
                                    Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>} {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Add New Camera</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Camera Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-5 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" autoFocus={true} value={cameraName} onChange={(e) => setCameraName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">MAC Address<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-5 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={macAddress} onChange={(e) => setMacAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">IP Address</p>
                                            </div>
                                            <div className="col-md-5 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">In Tag<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-5 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown ">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{inTag && <>{inTag}</>}{!inTag && <>Select Direction</>}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setInTag("towards")}>Towards</button>
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setInTag("away")}>Away</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Out Tag<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-5 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown ">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{outTag && <>{outTag}</>}{!outTag && <>Select Direction</>}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setOutTag("towards")}>Towards</button>
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setOutTag("away")}>Away</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Exclusion Camera</p>
                                            </div>
                                            <div className="col-md-5 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch1" checked={isExclusionCamera} onChange={() => setIsExclusionCamera(!isExclusionCamera)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                {isExclusionCamera && <>Yes</>}{!isExclusionCamera && <>No</>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className="col-md-5 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch2" checked={status} onChange={() => setStatus(!status)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch2"></label>
                                                {status && <>Active</>}{!status && <>Deactivated</>}
                                            </div>
                                        </div>
                                </div>
                                <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <Link type="button" className="btn btn-falcon-default btn-sm ml-2" to={siteUrl}>
                                                        <i className="fas fa-times"></i> Cancel</Link>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default Camera;