import { useEffect, useState } from "react";
import Loader from "../shared/loader";
import Enums from '../../utilities/enum';
import moment from 'moment';
import api from '../api/api';
import arrayUtilities from "../../utilities/arrayUtilities";
import { saveAs } from 'file-saver';

export default function ExportBreaches(props) {

    const [isLoading, setIsLoading] = useState(true);

    const [breachStatus, setBreachStatus] = useState(100);
    const [date, setDate] = useState(new Date());

    const [data, setData] = useState(null);

    const getBreaches = function() {

        setIsLoading(true);
        api.getBreachReports((data) => {
            setData(data);
            setIsLoading(false);
        }, () => {
            setData(null);
            setIsLoading(false);
        }, breachStatus, date, 0, props.organisationId, props.token);
    }

    const downloadCsv = function() {

        api.getBreachReports((blob, filename) => {
            saveAs(blob, filename);
        }, () => {
        }, breachStatus, date, 1, props.organisationId, props.token);
    }

    useEffect(() => {
        getBreaches();
    }, [breachStatus, date])

    return <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-4">
                                    <h5 className="mb-0">
                                        Export Breaches
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light border-top details-container">


                            <div className="row mb-3">
                                <label class="col-sm-2 col-form-label col-form-label-sm">Breach Status</label>
                                <div className="col-sm-10 col-xl-4">
                                    <select class="custom-select custom-select-sm" onChange={(e) => setBreachStatus(Number(e.target.value))} value={breachStatus}>
                                        {Object.keys(Enums.BreachStatus).map((item) =>
                                            <option value={item}>{Enums.BreachStatus[item]}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label class="col-sm-2 col-form-label col-form-label-sm">Date</label>
                                <div className="col-sm-10 col-xl-4">
                                    <div className="form-group mb-0">
                                        <input className="form-control form-control-sm mb-1" type="date" value={moment(date).format('yyyy-MM-DD')} onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <label class="col-sm-2">&nbsp;</label>
                                <div className="col-sm-10 col-xl-4">
                                    <button class="btn btn-sm btn-primary" onClick={downloadCsv}>Download CSV</button>
                                </div>
                            </div>

                            {isLoading &&
                                <Loader />
                            }

                            {!isLoading && 
                                 <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer mt-5">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th scope="col">VRM</th>
                                            <th scope="col">Reference Number</th>
                                            <th scope="col">Site Name</th>
                                            <th scope="col">Site Address</th>
                                            <th scope="col">Entry Time</th>
                                            <th scope="col">Entry Date</th>
                                            <th scope="col">Exit Time</th>
                                            <th scope="col">Exit Date</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Contravention</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!arrayUtilities.isNullOrEmpty(data) && data.map((dataItem, index) => <tr key={dataItem.referenceNumber} index={index}>
                                            <td>{dataItem.vrm}</td>
                                            <td>{dataItem.referenceNumber}</td>
                                            <td>{dataItem.siteName}</td>
                                            <td>{dataItem.siteAddress}</td>
                                            <td>{dataItem.entryTime}</td>
                                            <td>{dataItem.entryDate}</td>
                                            <td>{dataItem.exitTime}</td>
                                            <td>{dataItem.exitDate}</td>
                                            <td>{dataItem.duration}</td>
                                            <td>{dataItem.contravention}</td>

                                        </tr>)}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}