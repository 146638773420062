import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import authentication from './components/authentication/b2c';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_test_51JG2AqBudPOR8fGXdiUmiJyzNfcDRknpFZqIECETyG8vyh00aX6e95yOQ34QCv3Vbn84hCZ0plm40rwETg7UejBc00GrxxBWNu');

authentication.initialize();

authentication.run(() => {
    ReactDOM.render(
        <React.StrictMode>
            {/* <Elements stripe={stripePromise}> */}
                <App />
            {/* </Elements> */}
        </React.StrictMode>,
        document.getElementById('root')
    );
});
