import React from 'react';
import * as msal from 'msal'
const { NODE_ENV } = process.env;


// authentication.initialize({
//     instance: 'https://auth.advancedparking.tech/',
//     tenant: 'advancedparkingtech.onmicrosoft.com',
//     signInPolicy: 'B2C_1_susi',
//     applicationId: '6dcc6901-7272-4d1c-a132-aec66c821c3b',
//     clientId: '6dcc6901-7272-4d1c-a132-aec66c821c3b',
//     cacheLocation: 'sessionStorage',
//     scopes: ['https://advancedparkingtech.onmicrosoft.com/api/ApiAccess'],
//     redirectUri: redirectUrl,
//     postLogoutRedirectUri: window.location.origin,
// });

const state = {
  stopLoopingRedirect: false,
  config: {
    scopes: [],
    cacheLocation: null,
  },
  launchApp: null,
  accessToken: null,
  msalObj: null,
}

const LOCAL_STORAGE = 'localStorage'
const SESSION_STORAGE = 'sessionStorage'
const AUTHORIZATION_KEY = 'Authorization'

var isIE = function isIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ") > -1;
  var msie11 = ua.indexOf("Trident/") > -1;
  return msie || msie11;
};

var B2C_SCOPES = {
  API_ACCESS: {
    scopes: ['https://advancedparkingtech.onmicrosoft.com/api/ApiAccess'],
  }
};

const redirectUrl = NODE_ENV === 'production' ? 'https://app.advancedparking.tech' : 'http://localhost:3000';

var msalAppConfig = {
  auth: {
    clientId: '6dcc6901-7272-4d1c-a132-aec66c821c3b',
    authority: 'https://auth.advancedparking.tech/advancedparkingtech.onmicrosoft.com/b2c_1_susi',
    redirectUri: redirectUrl,
    validateAuthority: false,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: LOCAL_STORAGE,
    storeAuthStateInCookie: isIE()
  }
};

function acquireToken(successCallback) {
  const account = msalApp.getAccount();
  if (!account) {
      msalApp.loginRedirect(B2C_SCOPES.API_ACCESS)
  } else {
    msalApp.acquireTokenSilent(B2C_SCOPES.API_ACCESS).then(accessToken => {
      if (msalAppConfig.cache.cacheLocation === LOCAL_STORAGE) {
        window.localStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken.accessToken)
      } else {
        window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken.accessToken)
      }
      state.accessToken = accessToken
      if (state.launchApp) {
        state.launchApp()
      }
      if (successCallback) {
        successCallback()
      }
    }, error => {
      if (error) {
        msalApp.acquireTokenRedirect(B2C_SCOPES.API_ACCESS)
      }
    })
  }
}

let msalApp;

var authentication = {
  initialize: () => {
    msalApp = new msal.UserAgentApplication(msalAppConfig)
  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    var errorMessage;
    msalApp.handleRedirectCallback(error => {
      if (error) {
        errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
      }
    });
    setTimeout(function () { acquireToken() }, 1000);
  },
  required: (WrappedComponent, renderLoading) => {
    return class extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          signedIn: false,
          error: null
        }
      }
      render() {
        if (this.state.signedIn) {
          return (<WrappedComponent {...this.props} />)
        }

        return typeof renderLoading === 'function' ? renderLoading() : null
      }
    }
  },

  signOut: function signOut() {
    return msalApp.logout();
  },
  getAccessToken: function getAccessToken() {
    return state.accessToken;
  }
};

export default authentication;