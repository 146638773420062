import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Api from '../api/api';
import Enums from '../../utilities/enum';
import stringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';
var hash = require('object-hash');


export default function EditUser(props) {

    const [userRole, setUserRole] = useState(!arrayUtilities.isNullOrEmpty(props.user.organisations) ? props.user.organisations[0].roleId : null);
    const [clients, setClients] = useState(null);
    const [clientId, setClientId] = useState(!arrayUtilities.isNullOrEmpty(props.user.organisations) ? props.user.organisations[0].clientId : null);
    const [sites, setSites] = useState(null);
    const [siteIds, setSiteIds] = useState(!arrayUtilities.isNullOrEmpty(props.user.organisations) ? props.user.organisations[0].siteIds : null);

    const toggleSiteId = function (siteId) {
        setSiteIds(arrayUtilities.toggle(siteId, siteIds));
    };

    const removeUser = function (userId) {
        if (props.removeUser) {
            props.removeUser(userId);
        }
    }

    const cancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const save = function() {

        Api.editUserRole((data) => {
            if (props.onSave) {
                props.onSave();
            }
        }, () => {
            alert("Error");
        }, props.user.firstName, props.user.lastName, props.user.id, userRole, clientId, siteIds, props.organisationId, props.token);   
    }

    useEffect(() => {
        if (!stringHelpers.isNullOrEmpty(clientId)) {
            Api.getClientSites((data) => {
                setSites(data);
            }, () => {
                setSites(null);
                setSiteIds([]);                
            }, clientId, props.organisationId, props.token)
        } else {
            setSites(null);
            setSiteIds([]);
        }
    }, [clientId])

    useEffect(() => {
        Api.getClients((data) => {
            setClients(data);
        }, () => {}, props.organisationId, props.token);
    }, [])

    return <Modal show close onHide={cancel} size="lg">
        <Modal.Header>
            <Modal.Title>
                {props.user.firstName} {props.user.lastName}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-md-5 col-12 ">
                    <p className="font-weight-semi-bold mb-1">User Role*</p>
                </div>
                <div className="col-md-5 col-12 ">
                    <div className="form-group mb-0">
                        <div className="dropdown">
                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-role" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{userRole !== null && Enums.UserRoles[userRole]}{userRole === null && <>Select the User Role</>}</button>
                            <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-role" >
                                <div className="bg-white py-2 rounded-soft">
                                    {Object.keys(Enums.UserRoles).map((userRole, i = 0) => {
                                        i++;
                                        return (
                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setUserRole(Number(userRole))}>{Enums.UserRoles[userRole]}</button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(userRole === 4 || userRole === 5 || userRole === 6) && clients && clients !== null &&
                <div className="row">
                    <div className="col-md-5 col-12 ">
                        <p className="font-weight-semi-bold mb-1">Client</p>
                    </div>
                    <div className="col-md-5 col-12 ">
                        <div className="form-group mb-0">
                            <div className="dropdown">
                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-client" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {clientId !== null && <>{clients.find(x => x.id === clientId)?.name}</>}
                                    {clientId === null && <>Select client</>}
                                </button>
                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-client" >
                                    <div className="bg-white py-2 rounded-soft">
                                        {clients.map((client, index) =>
                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setClientId(client.id)} key={client.id} index={index}>{client.name}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(userRole === 4 || userRole === 5 || userRole === 6) && !stringHelpers.isNullOrEmpty(clientId) && !arrayUtilities.isNullOrEmpty(sites) &&
                <div className="row">
                    <div className="col-md-5 col-12 ">
                        <p className="font-weight-semi-bold mb-1">Sites</p>
                    </div>

                    <div className="col-md-5 col-12 ">
                        {sites.map((site, i) => {
                            return <div className="custom-control custom-checkbox" key={hash({ site, siteIds })} index={i}>
                                <input className="custom-control-input" type="checkbox" checked={siteIds.includes(site.id)} id={`site-${site.id}`} onChange={() => toggleSiteId(site.id)} />
                                <label className="custom-control-label col-form-label col-form-label-sm" htmlFor={`site-${site.id}`}>{site.name}</label>
                            </div>
                        }
                        )}
                    </div>
                </div>
            }
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
                <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => removeUser(props.user.id)}>Remove</button>

                <div>
                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={cancel}>Cancel</button>
                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={save}>Save</button>
                </div>
        </Modal.Footer>
    </Modal>
}