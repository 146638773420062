import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications'
import Pagination from '../shared/pagination';

function EnforcementDashboard(props) {
    const [type, setType] = useState(null);
    const [status, setStatus] = useState(null);
    const [search, setSearch] = useState("");
    const [filterEnd, setFilterEnd] = useState(null);
    const [filterEndTemp, setFilterEndTemp] = useState(null);
    const [filterStart, setFilterStart] = useState(null);
    const [filterStartTemp, setFilterStartTemp] = useState(null);

    const [sites, setSites] = useState(null);

    const [processed, setProcessed] = useState(null);
    const [cancelled, setCancelled] = useState(null);
    const [voided, setVoided] = useState(null);
    const [dvlaMismatch, setDvlaMismatch] = useState(null);
    const [dvlaNoTrace, setDvlaNoTrace] = useState(null);
    const [dvlaRequested, setDvlaRequested] = useState(null);
    const [dvlaRecieved, setDvlaRecieved] = useState(null);

    const [allStatuses, setAllStatuses] = useState(null);

    const [breachesToAllocate, setBreachesToAllocate] = useState(null);
    const [selectedSites, setSelectedSites] = useState([]);
    const [canAllocate, setCanAllocate] = useState(false);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState(null);

    const { addToast } = useToasts();

    var _breachesAllocateAbortController = null;
    var _breachesCancelAbortController = null;
    var _breachesMismatchAbortController = null;
    var _breachesNotraceAbortController = null;
    var _breachesRecievedAbortController = null;
    var _breachesRequestedAbortController = null;
    var _breachesVoidAbortController = null;
    var _breachesAbortController = null;

    const clearFilters = function () {
        setSearch("");
        setStatus(null);
        setType(null);
        setFilterStart(null);
        setFilterStartTemp(null);
        setFilterEnd(null);
        setFilterEndTemp(null);
    };

    const setFilterDates = function () {
        setFilterStart(filterStartTemp);
        setFilterEnd(filterEndTemp);
    };

    const updateSites = function (value, index) {
        var tempArray = [];
        if (sites !== null) {
            sites.forEach(item => {
                tempArray.push(0);
            });
            if (!value) {
                tempArray[index] = 1;
            }
        }
        setSelectedSites(tempArray);
    };

    const allocateTickets = function (reallocate) {
        var i = 0;
        var status = [100, 101, 102, 103, 104, 105, 200, 201, 202, 210, 211, 212, 220, 221, 222, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 400, 401, 402, 403, 501, 503];
        while (i < sites.length) {
            if (selectedSites[i] === 1) {
                if (_breachesAllocateAbortController) {
                    _breachesAllocateAbortController.abort();
                }
                _breachesAllocateAbortController = new AbortController();
                const signal = _breachesAllocateAbortController.signal;
                Api.getBreaches(
                    (data) => {
                        setBreachesToAllocate(data);
                        updateSites(true, null);
                    },
                    (error) => console.log(error),
                    null, sites[i].id, null, reallocate, null, null, null, status, 0,
                    props.organisationId,
                    props.token,
                    signal
                )
            }
            i++;
        }
    };

    const getUsers = function () {
        Api.getUsers(
            (data) => setUsers(data),
            (error) => console.log(error),
            0,
            props.organisationId,
            props.token
        )
    };

    useEffect(() => {
        updateSites(true, null);
    }, [sites]);

    useEffect(() => {
        if (user !== null && breachesToAllocate !== null && breachesToAllocate.items !== []) {
            var idsToAllocate = [];
            breachesToAllocate.items.forEach(breach => {
                idsToAllocate.push(breach.id);
            });
            Api.assignTickets(
                () => {
                    addToast("Tickets assigned successfully", {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                    getSites();
                    setUser(null);
                },
                (error) => console.log(error),
                user.id,
                idsToAllocate,
                props.organisationId,
                props.token
            )
        }
    }, [breachesToAllocate]);

    useEffect(() => {
        if (user === null) {
            setCanAllocate(false);
        } else {
            setCanAllocate(false);

            selectedSites.forEach(site => {
                if (site === 1) {
                    setCanAllocate(true);
                }
            });

        }
    }, [user, selectedSites]);

    const getTicketNumbers = function () {
        //processed
        if (_breachesAbortController) {
            _breachesAbortController.abort();
        }
        _breachesAbortController = new AbortController();
        const signal = _breachesAbortController.signal;
        Api.getBreaches(
            (data) => setProcessed(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [100, 101, 102, 103, 104, 105, 200, 201, 202, 210, 501, 503], 0, props.organisationId, props.token, signal
        );

        //Cancelled
        if (_breachesCancelAbortController) {
            _breachesCancelAbortController.abort();
        }
        _breachesCancelAbortController = new AbortController();
        const signal2 = _breachesCancelAbortController.signal;
        Api.getBreaches(
            (data) => setCancelled(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [501], 0, props.organisationId, props.token, signal2
        );

        //Voided
        if (_breachesVoidAbortController) {
            _breachesVoidAbortController.abort();
        }
        _breachesVoidAbortController = new AbortController();
        const signal3 = _breachesVoidAbortController.signal;
        Api.getBreaches(
            (data) => setVoided(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [503], 0, props.organisationId, props.token, signal3
        );

        //DVLA Mismatch
        if (_breachesMismatchAbortController) {
            _breachesMismatchAbortController.abort();
        }
        _breachesMismatchAbortController = new AbortController();
        const signal4 = _breachesMismatchAbortController.signal;
        Api.getBreaches(
            (data) => setDvlaMismatch(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [104], 0, props.organisationId, props.token, signal4
        );

        //DVLA No Trace
        if (_breachesNotraceAbortController) {
            _breachesNotraceAbortController.abort();
        }
        _breachesNotraceAbortController = new AbortController();
        const signal5 = _breachesNotraceAbortController.signal;
        Api.getBreaches(
            (data) => setDvlaNoTrace(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [102], 0, props.organisationId, props.token, signal5
        );

        //DVLA Requested
        if (_breachesRequestedAbortController) {
            _breachesRequestedAbortController.abort();
        }
        _breachesRequestedAbortController = new AbortController();
        const signal6 = _breachesRequestedAbortController.signal;
        Api.getBreaches(
            (data) => setDvlaRequested(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [100], 0, props.organisationId, props.token, signal6
        );

        //DVLA Recieved
        if (_breachesRecievedAbortController) {
            _breachesRecievedAbortController.abort();
        }
        _breachesRecievedAbortController = new AbortController();
        const signal7 = _breachesRecievedAbortController.signal;
        Api.getBreaches(
            (data) => setDvlaRecieved(data),
            (error) => console.log(error),
            null, null, null, null, null, null, null, [101], 0, props.organisationId, props.token, signal7
        );
    };

    const getSites = function () {
        Api.getSites(
            (data) => setSites(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const setStatuses = function () {
        var temp = [];
        for (let index = 1; index < 505; index++) {
            if (Enums.BreachStatus[index] !== undefined) {
                temp.push(index);
            }
        }
        setAllStatuses(temp);
    };

    useEffect(() => {
        getSites();
        getTicketNumbers();
        setStatuses();
        getUsers();
    }, []);

    return (
        <>
            {dvlaRecieved === null && <Loader />}
            {cancelled !== null && voided !== null && dvlaMismatch !== null && dvlaNoTrace !== null && dvlaRecieved !== null && dvlaRequested !== null && processed !== null &&
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-1 d-flex align-items-center pr-0">
                                                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Enforcement Dashboard</h5>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-falcon-default btn-sm mr-2" data-toggle="modal" data-target="#re-allocateModal">Re-Allocate</button>
                                                <button className="btn btn-falcon-default btn-sm" data-toggle="modal" data-target="#allocateModal">Allocate</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center enforcement-row">
                            <div className=" mb-3 reporting-card">
                                <button onClick={() => setStatus(null)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">Processed</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {processed.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(501)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">Cancelled</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {cancelled.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(503)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">Voided</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {voided.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(104)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">DVLA Mismatch</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {dvlaMismatch.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(102)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">DVLA No Trace</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {dvlaNoTrace.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(100)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">DVLA Requested</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {dvlaRequested.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="mb-3 reporting-card">
                                <button onClick={() => setStatus(101)} className="text-left no-hover button-no-style px-0">
                                    <div className="card h-md-100 ecommerce-card-min-width">
                                        <div className="card-header pb-0">
                                            <h6 className="mb-0 mt-2 d-flex align-items-center">DVLA Recieved</h6>
                                        </div>
                                        <div className="card-body d-flex flex-column justify-content-end">
                                            {dvlaRecieved.totalCount}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-1 d-flex align-items-center pr-0">
                                                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Enforcement Tickets</h5>
                                            </div>
                                            <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                                <div className="input-group mr-1">
                                                    {search === null && <>
                                                        <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                    </>}
                                                    {search !== null && <>
                                                        <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                    </>}
                                                    <div className="input-group-append">
                                                        <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                                    </div>
                                                </div>
                                                <button className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                                <div className="filter-buttons" id="select-type">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type !== null && <>Type: {Enums.ContraventionTypes[type]}</>}{type === null && <>Filter by Type</>} </button>
                                                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-type">
                                                        <div className="bg-white py-3 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(null)}>All</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(0)}>ANPR</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(1)}>MNPR</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="filter-buttons" id="select-status">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status !== null && <>Status: {Enums.BreachStatus[status]}</>}{status === null && <>Filter by Status</>}</button>
                                                    <div className="dropdown-menu py-0 " aria-labelledby="select-status">
                                                        <div className="bg-white py-3 rounded-soft tall-dropdown">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(null)}>All</button>
                                                            {allStatuses !== null && allStatuses.map((item) => {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(item)}>{Enums.BreachStatus[item]}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group mb-0">
                                                                {!filterStartTemp &&
                                                                    <>
                                                                        <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterStartTemp(e.target.value)} />
                                                                    </>}
                                                                {filterStartTemp &&
                                                                    <input className="form-control mb-1" type="datetime-local" value={filterStartTemp} onChange={(e) => setFilterStartTemp(e.target.value)} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group mb-0">
                                                                {!filterEndTemp &&
                                                                    <>
                                                                        <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterEndTemp(e.target.value)} />
                                                                    </>}
                                                                {filterEndTemp &&
                                                                    <input className="form-control mb-1" type="datetime-local" value={filterEndTemp} onChange={(e) => setFilterEndTemp(e.target.value)} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                        Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="allocateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="deleteModalLabel">Select a new Site Breach Allocation</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <h5 className="text-danger font-weight-semi-bold">Important:</h5>
                                                            <p className="font-weight-bold mt-1 mb-1">This will allocate the next 100 tickets currently not allocated to any user for the selected site.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1"><button className="button-no-style tag-list-button collapsed" id="sites" type="button" data-toggle="collapse" data-target="#siteList" aria-expanded="true" aria-controls="siteList">Select Site:</button></p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="checkbox-dropdown" id="siteList" aria-labelledby="Tag List" data-parent="#sites">
                                                                <div className="row justify-content-start">
                                                                    {sites !== null && sites.map((site, i) => {
                                                                        var isSelected = false;
                                                                        if (selectedSites[i] !== 0) {
                                                                            isSelected = true;
                                                                        }
                                                                        return (
                                                                            <div className="col-6">
                                                                                <div className="form-group mb-0">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input className="custom-control-input" type="checkbox" checked={isSelected} id={site.id} onChange={(e) => { updateSites(isSelected, i) }} />
                                                                                        <label className="custom-control-label" htmlFor={site.id}>{site.name}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold pl-1 mt-1 mb-1">Select User</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1 edit-dropdown" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{user === null && <>Select User</>}{user !== null && <>{user.firstName} {user.lastName}</>}</button>
                                                            <div className="dropdown-menu py-0" aria-labelledby="select-site" >
                                                                <div className="bg-white py-3 rounded-soft">
                                                                    {users && users !== null && users.map((user) => {
                                                                        if (user.organisations[0].roleId === 0 || user.organisations[0].roleId === 1 || user.organisations[0].roleId === 2) {
                                                                            return (
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setUser(user)}>{user.firstName} {user.lastName}</button>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" disabled={!canAllocate} onClick={() => allocateTickets(false)}>
                                                        Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="re-allocateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="deleteModalLabel">Select a new Site Breach Re-Allocation</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <h5 className="text-danger font-weight-semi-bold">Important:</h5>
                                                            <p className="font-weight-bold mt-1 mb-1">This will allocate the next 100 tickets allocated to any user for the selected site.<br />Including tickets already allocated to a user.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1"><button className="button-no-style tag-list-button collapsed" id="sites" type="button" data-toggle="collapse" data-target="#siteList" aria-expanded="true" aria-controls="siteList">Select Site:</button></p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="checkbox-dropdown" id="siteList" aria-labelledby="Tag List" data-parent="#sites">
                                                                <div className="row justify-content-start">
                                                                    {sites !== null && sites.map((site, i) => {
                                                                        var isSelected = false;
                                                                        if (selectedSites[i] !== 0) {
                                                                            isSelected = true;
                                                                        }
                                                                        return (
                                                                            <div className="col-6">
                                                                                <div className="form-group mb-0">
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input className="custom-control-input" type="checkbox" checked={isSelected} id={site.id} onChange={(e) => { updateSites(isSelected, i) }} />
                                                                                        <label className="custom-control-label" htmlFor={site.id}>{site.name}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold pl-1 mt-1 mb-1">Select User</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1 edit-dropdown" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{user === null && <>Select User</>}{user !== null && <>{user.firstName} {user.lastName}</>}</button>
                                                            <div className="dropdown-menu py-0" aria-labelledby="select-site" >
                                                                <div className="bg-white py-3 rounded-soft">
                                                                    {users && users !== null && users.map((user) => {
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setUser(user)}>{user.firstName} {user.lastName}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" disabled={!canAllocate} onClick={() => allocateTickets(null)}>
                                                        Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="tickets-table">
                                            <div className="header-row">
                                                <div className="table-header">Site</div>
                                                <div className="table-header">Number of Tickets</div>
                                            </div>
                                            <div className="table-body">
                                                {sites !== null && sites.map((site) => {
                                                    return (
                                                        <SiteBreaches {...props} siteId={site.id} siteName={site.name} search={search} start={filterStart} end={filterEnd} type={type} status={status} />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default EnforcementDashboard;

function SiteBreaches(props) {
    const [breaches, setBreaches] = useState(null);
    const [breachesList, setBreachesList] = useState(null);
    const [siteId, setSiteId] = useState(props.siteId)
    const [buttonId, setButtonId] = useState("");
    const [listId, setListId] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    var _breachesAbortController = null;

    const getBreaches = function () {
        var userId = null;
        if (props.allocatedFilter) {
            userId = 7;
        }
        var status = [100, 101, 102, 103, 104, 105, 200, 201, 202, 210, 211, 212, 220, 221, 222, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 400, 401, 402, 403, 501];
        if (props.status !== null) {
            status = [props.status];
        }
        if (_breachesAbortController) {
            _breachesAbortController.abort();
        }
        _breachesAbortController = new AbortController();
        const signal = _breachesAbortController.signal;
        Api.getBreaches(
            (data) => {
                setBreaches(data);
                setLoading(false);
            },
            (error) => console.log(error),
            props.search, props.siteId, null, null, props.start, props.end, props.type, status, pageNumber,
            props.organisationId,
            props.token, signal
        )
    };

    const isLastPage = function () {
        if (breaches !== null && breaches !== undefined) {
            setLastPage(false);
            setPageCount(Math.ceil(breaches.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(breaches.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };

    const changePageNumber = function (num) {
        setPageNumber(num);
    };

    useEffect(() => {
        if (siteId !== null) {
            getBreaches();
            setButtonId("#button-" + siteId);
            setListId("#list-" + siteId);
        }
    }, [siteId])

    useEffect(() => {
        if (breaches !== null && breaches !== undefined && breaches.items !== undefined && breaches.list !== null) {
            setBreachesList(breaches.items);
        }
        isLastPage();

    }, [breaches])

    useEffect(() => {
        setLoading(true)
        getBreaches();
    }, [pageNumber])

    useEffect(() => {
        setLoading(true)
        getBreaches();
        setPageNumber(0)
    }, [props.search, props.start, props.end, props.type, props.status]);


    useEffect(() => {
        setLoading(true)
        getBreaches();
        setPageNumber(0)
    }, []);

    return (<>
        {breaches !== null &&
            <>
                <div className="table-row">
                    <button className="table-item button-no-style text-left p-0 collapsed" id={buttonId.substring(1)} type="button" data-toggle="collapse" data-target={listId} aria-expanded="true" aria-controls={buttonId.substring(1)}>{props.siteName} <i className="fas fa-chevron-down"></i></button>
                    <div className="table-item">{breaches.totalCount}</div>
                </div>
                <div className="collapse" id={listId.substring(1)} aria-labelledby="Site Breaches List" data-parent={buttonId}>
                    {!loading && <>
                        <div className="card-body bg-light border-top details-container" >
                            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                <thead className="bg-200 text-900">
                                    <tr>
                                        <th>Reference Number</th>
                                        <th className="text-center">VRM</th>
                                        <th>Site</th>
                                        <th>Contravention</th>
                                        <th className="text-center">Contravention Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {breachesList !== null && <>
                                        {breachesList !== undefined && breachesList.map((breach) => {
                                            var url = '/organisation/' + props.organisationId + '/breach/' + breach.id;
                                            if (breach.breachStatus === 200 || breach.breachStatus === 210) {
                                                return (
                                                    <tr className="action-required">
                                                        <td><Link to={url} title="Action Required"><h6 className="mb-0">{breach.referenceNumber !== null && <>{breach.referenceNumber}</>}{breach.referenceNumber === null && <>No Reference Number</>} <i className="fas fa-lg fa-exclamation-circle text-danger" ></i></h6></Link></td>
                                                        <td><h6 className="m-auto mb-0 small-number-plate">{breach.vrm}</h6></td>
                                                        <td>{breach.siteName}</td>
                                                        <td>{breach.contraventionName}</td>
                                                        <td className="text-center">{breach.timestamp !== null && <>{moment(breach.timestamp).format("DD/MM/yyyy")}</>}</td>
                                                        <td>{Enums.BreachStatus[breach.breachStatus]}</td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0">{breach.referenceNumber !== null && <>{breach.referenceNumber}</>}{breach.referenceNumber === null && <>No Reference Number</>}</h6></Link></td>
                                                        <td><h6 className="m-auto mb-0 small-number-plate">{breach.vrm}</h6></td>
                                                        <td>{breach.siteName}</td>
                                                        <td>{breach.contraventionName}</td>
                                                        <td className="text-center">{breach.timestamp !== null && <>{moment(breach.timestamp).format("DD/MM/yyyy")}</>}</td>
                                                        <td>{Enums.BreachStatus[breach.breachStatus]}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </>}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-center pt-1 row align-items-center">
                                <div className="col-auto align-items-center">
                                    <Pagination totalResults={breaches.totalCount} currentPage={pageNumber} changePageNumber={changePageNumber} />
                                </div>
                            </div>
                        </div>
                    </>}
                    {loading && <div className="small-loader">
                        <Loader />
                    </div>}
                </div>
            </>
        }
    </>)
}