import React from 'react';
import Loader from './loader';

function Pagination(props) {

    const setPageNumber = function (num) {
        props.changePageNumber(num);
    };

    var numberOfPages = [];

    if(props.totalResults !== undefined){
    for (let index = 0; index < Math.ceil(props.totalResults / 100); index++) {
        numberOfPages.push(index)
    };
    return (
        <>
            <div className="row justify-content-center showing-items">Showing {Math.min(1 * (props.currentPage * 100) + 1, props.totalResults).toLocaleString()} {props.totalResults !== 0 && <>to {Math.min(props.totalResults, (props.currentPage + 1) * 100).toLocaleString()} of {props.totalResults.toLocaleString()}</>} items</div>
            {props.totalResults > 100 &&
                <>
                    <ul className="pagination align-items-end">
                        {props.currentPage > 0 &&
                            <button className="btn btn-sm btn-falcon-default me-1" type="button" title="Previous" data-list-pagination="prev" onClick={() => setPageNumber(props.currentPage - 1)}><i className="fas fa-lg fa-angle-left"></i></button>
                        }
                        {numberOfPages.length !== 0 && numberOfPages.length <= 7 && numberOfPages.map((i) => {
                            i++;
                            return (
                                <>
                                    {i - 1 === props.currentPage && <>
                                        <li className="active">
                                            <button type="button" className="btn mx-1 btn-falcon-default current-page">
                                                {i}
                                            </button>
                                        </li>
                                    </>}
                                    {i - 1 !== props.currentPage && <>
                                        <li>
                                            <button type="button" className="btn mx-1 btn-falcon-default" onClick={() => setPageNumber(i - 1)}>
                                                {i}
                                            </button>
                                        </li>
                                    </>}
                                </>
                            )
                        })
                        }
                        {numberOfPages.length > 7 &&
                            <>
                                {props.currentPage > 3 && <>
                                    <li>
                                        <button type="button" className="btn mx-1 btn-falcon-default" onClick={() => setPageNumber(0)}>
                                            {1}
                                        </button>
                                    </li>
                                    {props.currentPage > 4 && <>
                                        <i className="fas fa-ellipsis-h"></i>
                                    </>}
                                </>}
                                {numberOfPages.map((i) => {
                                    if (i >= Math.max(0, props.currentPage - 3) && i <= Math.min(numberOfPages.length, props.currentPage + 3)) {
                                        return (
                                            <>
                                                {i === props.currentPage && <>
                                                    <li className="active">

                                                        <button type="button" className="btn mx-1 btn-falcon-default current-page">
                                                            {(i + 1).toLocaleString()}
                                                        </button>
                                                    </li>
                                                </>}
                                                {i !== props.currentPage && <>
                                                    <li>

                                                        <button type="button" className="btn mx-1 btn-falcon-default" onClick={() => setPageNumber(i)}>
                                                            {(i + 1).toLocaleString()}
                                                        </button>
                                                    </li>
                                                </>}
                                            </>
                                        )
                                    }
                                    i++;
                                })}
                                {props.currentPage < numberOfPages.length - 4 && <>
                                    {props.currentPage + 3 < numberOfPages.length - 2 && <>
                                        <i className="fas fa-ellipsis-h"></i>
                                    </>}
                                    <button type="button" className="btn mx-1 btn-falcon-default" onClick={() => setPageNumber(numberOfPages.length - 1)}>
                                        {numberOfPages.length.toLocaleString()}
                                    </button>
                                </>}
                            </>}
                        {props.currentPage < numberOfPages.length - 1 &&
                            <button className="btn btn-sm btn-falcon-default me-1" type="button" title="Previous" data-list-pagination="prev" onClick={() => setPageNumber(props.currentPage + 1)}><i className="fas fa-lg fa-angle-right"></i></button>
                        }
                    </ul>
                </>}
        </>
    )} else {
        return (
            <Loader />
        )
    }
}
export default Pagination;