export default {

    emptyGuid: "00000000-0000-0000-0000-000000000000",

    cleanVrm(vrm) {
        const regex = new RegExp(/[^a-zA-Z0-9]/g);
        
        let result = vrm.replace(regex, "");
        result = result.toUpperCase();

        return result;
    },

    isNullOrEmpty(str) {
        return !str || str === null || str === "" || Array.isArray(str) || str.trim() === "";
    },

    anyAreNullOrEmpty(strings) {

        let result = false;
        strings.forEach(str => {
            if (this.isNullOrEmpty(str)) {
                result = true;
            }
        });
        return result;
    },

    isNullOrWhiteSpace(str) {
        return !str || str === null || str.trim().length === 0;
    },

    textSizeClass(amount) {

        if (!isNaN(amount)) {
            if (Math.abs(amount) >= 100000000) {
                return " text-xs";
            }
            if (Math.abs(amount) >= 10000000) {
                return " text-sm";
            }
            if (Math.abs(amount) >= 1000000) {
                return " text-md";
            }
            if (Math.abs(amount) >= 100000) {
                return " text-lg";
            }
        }
        return "";
    },

    toInitials(nameParts) {
        if (nameParts && nameParts !== null && nameParts.length > 0) {
            let result = "";

            for (let i = 0; i < nameParts.length; i++)
            {
                if (!this.isNullOrWhiteSpace(nameParts[i])) {
                    result += nameParts[i].substr(0, 1).toUpperCase();
                }
            }
            
            return result.substr(0, Math.min(2, result.length));
        }

        return "";
    },

    photoFormat(contentType) {
        if (!this.isNullOrWhiteSpace(contentType)) {
            switch (contentType.toLowerCase()) {
                case "image/bmp":
                    return "bmp";
                case "image/gif":
                    return "gif";
                case "image/jpeg":
                    return "jpg";
                case "image/png":
                    return "png";
            }
        }

        return null;
    }
};
